import { createSlice } from '@reduxjs/toolkit'

export const CHAT_WIDGET_SLICE_NAME = 'chatWidget';
const initialState = ''
  
export const chatWidgetSlice = createSlice({
  name: CHAT_WIDGET_SLICE_NAME,
  initialState,
  reducers: {
    chatWidgetReducer: (state = initialState, action) => {
      return {
        ...state, 
        ...action.payload
      }
    }
  },
})

// Action creators are generated for each case reducer function
export const { chatWidgetReducer } = chatWidgetSlice.actions

export default chatWidgetSlice.reducer