import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios';
import App from './App'
import './assets/scss/style.scss'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { persistor, store } from './redux2/store';
import { PersistGate } from 'redux-persist/integration/react';

// request interceptor
axios.interceptors.request.use( request => {
  const accessToken = localStorage.getItem('accessToken');

  if(accessToken){
      request.headers['Authorization'] = `${accessToken}}`;
  }
  
  return request;
},
error => {
  return Promise.reject(error);
}
);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();